import React from 'react';

function Software() {
  return (
    <div>
      <p><br />See the <a href="https://github.com/eth-easl">EASL GitHub</a> for our research project code, including:
      <br /></p>
      <ul>
        <li><b><a href="https://github.com/eth-easl/dirigent">Dirigent</a></b>: a lightweight cluster manager for serverless, supporting high instance churn</li>
        <li><b><a href="https://github.com/eth-easl/orion">Orion</a></b>: a fine-grained scheduler for interference-aware GPU sharing across ML workloads</li>
        <li><b><a href="https://github.com/eth-easl/modyn">Modyn</a></b>: a platform for ML training on dynamic datasets, exploring data selection and training triggering policies</li>
        <li><b><a href="https://github.com/eth-easl/deltazip">DeltaZip</a></b>: a framework for efficient multi-tenant serving of fine-tuned LLMs</li>
        <li><b><a href="https://github.com/eth-easl/cachew">Cachew</a></b>: a system that enables efficient distributed input data processing for ML training jobs (builds on <a href="https://www.tensorflow.org/guide/data">tf.data</a>)</li>
        <li><b><a href="https://github.com/vhive-serverless/invitro">InVitro</a></b>: a set of tools for representative serverless workload performance analysis</li>
        <li><b><a href="https://github.com/stanford-mast/pocket">Pocket</a></b>: a distributed, elastic data store for ephemeral data, designed for serverless computing applications</li>
        <li><b><a href="https://github.com/stanford-mast/reflex">ReFlex</a></b>: a software system that enables fast, predictable access to remote Flash storage</li>
      </ul>
      <br />
    </div>
  );
}

export default Software;
