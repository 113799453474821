import React from 'react';

function About() {
  return (
    <div data-nosnippet>
    <p><br />Outside of research, I enjoy:
    <br /></p>
    <ul>
    <li><b>Sports</b>: tennis, skiing, cycling, volleyball, swimming, ... </li>
    <li><b>Travel</b>: exploring new places and cultures</li>
    <li><b>Art</b>: painting, sketching</li>
    <li><b>Music</b>: piano, singing, violin</li>
    </ul>
    <br />
    </div>
  );
}

export default About;
