import React from 'react';

function Talks() {
  let ml_data_proc=process.env.PUBLIC_URL+"/talks/SoCC22_keynote_ml_data_proc.pdf"
  let thesis=process.env.PUBLIC_URL+"/talks/fast_elastic_cloud_storage.pdf"
  
  return (
    <div>
      <br />
      Slides from selected talks:
      <ul>
        <li><b><a href={ml_data_proc} target= "_blank" rel="noopener noreferrer">Scalable Input Data Processing for Resource-Efficient ML</a></b>, SoCC'22 keynote, 2022. </li>
        <li><b><a href={thesis} target= "_blank" rel="noopener noreferrer">Fast, Elastic Storage for the Cloud</a></b>, PhD Dissertation, 2019. </li>
      </ul>
      <br />
    </div>
  );
}

export default Talks;
