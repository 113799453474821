import React from 'react';
import img_sml from './anaklimovic_photo_200_222.jpg';
import img_med from './anaklimovic_photo_400_445.jpg';
import img_lrg from './anaklimovic_photo_800_890.jpg';
import img_xlg from './anaklimovic_photo_1600_1779.jpg';

function AnaPortrait() {
  return (
    <picture>
      <source media="(min-width: 1200px)"
        srcSet={`${img_lrg} 1x, ${img_xlg} 2x`} ></source>
      <source media="(min-width: 900px)"
        srcSet={`${img_med} 1x, ${img_lrg} 2x`} ></source>
      <source media="(min-width: 600px)"
        srcSet={`${img_sml} 1x, ${img_med} 2x`} ></source>
      <img src={img_med} alt="Ana Portrait" className="img-fluid rounded"/>
    </picture>
  );
}

export default AnaPortrait;
