import React from 'react';

function Research() {
  return (

    <div className="accordion pb-5 pt-3" id="accordionExample" style={{width:"100%"}}>
      <div className="card">
        <div className="card-header" id="headingOne">
          <h2 className="mb-0">
            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
              Serverless Computing: A New Wave of Cloud Computing
            </button>
          </h2>
        </div>
        <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
          <div className="card-body">
            <p>Cloud computing is undergoing a fundamental shift, stimulated by an exponential growth in data, users, and an increasing demand for cloud services that can automatically allocate and scale computing resources for jobs. An emerging wave of cloud computing, called serverless computing, enables users to focus on writing code for their applications while cloud providers manage resources based on application demands. On serverless computing platforms, users can simultaneously launch thousands of tiny, short-lived tasks and pay only for the resources their tasks actually consume per ~10ms time interval, as opposed to paying for pre-allocated virtual machines that have fixed ratios of compute, memory, and storage.
            <br /><br /><i>Research topics:</i> What should an operating system for serverless computing look like? Scheduling millions of short-lived tasks to satisfy performance requirements and achieve high resource utilization poses interesting challenges. Serverless computing encourages a high degree of resource sharing across tenants, which poses performance and security isolation concerns. In addition, it is not yet clear what is the right abstraction for users to specify application performance requirements.</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingTwo">
          <h2 className="mb-0">
            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
              Computer System Design for Machine Learning
            </button>
          </h2>
        </div>
        <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
          <div className="card-body">
            <p>Machine learning (ML) jobs are an increasingly important class of applications in the cloud. Across domains such as image understanding and text translation, scaling machine learning models to a large number of parameters has been shown to dramatically improve accuracy when sufficiently large datasets are used. While significant work has focused on optimizing hardware and software for ML computations, data management is a common bottleneck. As organizations collect massive amounts of data, <a href="https://www.sigarch.org/rethinking-data-storage-and-preprocessing-for-ml/">storing and ingesting data at this scale poses several challenges</a>. 
	          <br /><br /><i>Research topics:</i> How should we design distributed storage systems for machine learning to optimize end-to-end model training and inference? How can we avoid moving large amounts of data across the network — should we instead move computation closer to the data (near-storage computing)? How can multiple tenants safely share datasets and models with good performance guarantees?</p>
          </div>
        </div>
      </div>
      <div className="card">
        <div className="card-header" id="headingThree">
          <h2 className="mb-0">
            <button className="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
              Machine Learning for Computer System Optimization
            </button>
          </h2>
        </div>
        <div id="collapseThree" className="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
          <div className="card-body">
            <p>Many of today’s computer systems use heuristics and hints to make decisions (e.g., to decide which resources to allocate for a task or which data to keep in a cache). As software applications and hardware platforms become more and more heterogeneous, designing heuristics is increasingly difficult. Yet due to growing heterogeneity, automating resource and data management is increasingly important. One promising approach is to learn resource management strategies by training machine learning models using system data collected while profiling or running applications. 
            <br /> <br /><i>Research topics:</i> How can we leverage machine learning models to make systems-level decisions when such decisions often need to be made at microsecond timescales? How should we design APIs to make replacing or supplementing heuristics with machine learning model inference practical in computer systems?</p>
          </div>
        </div>
      </div>
    </div>
  );
}


export default Research;
