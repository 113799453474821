import React from 'react';

function Intro() {
  return (
    <div>
      <p>I am an Assistant Professor in the <a href="https://inf.ethz.ch/">Department of Computer Science</a> at <a href="https://ethz.ch/en.html">ETH Zurich</a>. I am a member of the <a href="https://www.systems.ethz.ch/">ETH Systems Group</a>, where I lead the Efficient Architectures and Systems Lab (<a href="https://anakli.inf.ethz.ch#Group">EASL</a>).<br/></p>

      <p>I work on computer systems for large-scale applications such as cloud computing services, data analytics, and machine learning. The goal of my research is to improve the performance and resource efficiency of cloud computing while making it easier for users to deploy and manage their applications. My research interests span operating systems, computer architecture, and their intersection with machine learning.</p>
      
      <p>Before joining ETH, I spent a year as a Research Scientist at <a href="https://ai.google/research/teams/brain/">Google Brain</a>. I completed my Ph.D. in <a href="http://ee.stanford.edu/">Electrical Engineering</a> at <a
      href="http://www.stanford.edu/">Stanford University</a>, advised by Professor <a
      href="https://web.stanford.edu/~kozyraki/">Christos Kozyrakis</a>. My dissertation
      was on the design and implementation of fast, elastic storage for cloud computing. My Ph.D.
      was generously supported by the <a
      href="http://research.microsoft.com/en-us/collaboration/global/northam/northam-fellows.aspx">Microsoft Research PhD Fellowship</a> and <a href="https://vpge.stanford.edu/fellowships-funding/sgf">Stanford Graduate Fellowship</a>. I earned my M.S. in Electrical Engineering at Stanford University in 2015. I graduated from the <a href="http://engsci.utoronto.ca/">Engineering Science</a> program at the <a href="http://www.utoronto.ca/">University of Toronto</a> in 2013, where I earned my Bachelor of Applied Science and Engineering. 
      </p>
      
      <p><b>If you are interested in joining the EASL research group, please email me (<a href="mailto:aklimovic@ethz.ch">aklimovic&#64;ethz.ch</a>) with your CV. See below for research focus areas.</b></p>
      <p><br /></p>
    </div>
  );
}

export default Intro;
